$mobile-width: 480px;
$desktop-width: 1440px;
$laptop-width: 992px;
$form-width: 767px;
$dark-blue-color: #233464;
$blue-unique: #173f5f;
$blue-unique2: #20639b;
$blue: #0966f1;
$light-blue: #d5f3fe;
$blue2: #3c99dc;
$blue3: #66d3fa;
$light-gray: #cfd7e4;
$yellow: #f6d55c;
$red: #ed553b;
$red-p: #ff0d00;
$green: #3caea3;
$carrot: #ed9121;
$orange: #fbdeb3;
$dark-orange: #efb261;

$mobile-width: 480px;

// Mixin
@mixin mobile() {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}
// End mixin

// @font-face {
//   font-family: "Lato-regular";
//   src: url("./assets/fonts/Lato-Regular.ttf");
//   font-display: swap;
// }
.base-font-class {
  margin-bottom: 2em;
  letter-spacing: 0.12em;
  word-spacing: 0.16em;
  line-height: 1.5em;
}
.normal-font {
  @extend .base-font-class;
  font-family: "Lato-regular", "sans-serif";
}
* {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
body {
  font-size: 16px;
  font-family: "Segoe UI", sans-serif;
  box-sizing: border-box;
  min-height: 100%;
  display: grid;

  //   font-weight: 400;
  //   line-height: 1.5;
}
#root {
  display: grid;
  grid-template-rows: auto 1fr min-content;
  grid-template-columns: 100%;
}
.paragraph,
.span {
  max-width: 80ch;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", "sans-serif";
  color: #233464;
  // line-height: 1.5rem;
}
.page-header {
  font-size: 2.5rem;

  @include mobile() {
    font-size: 1.75rem;
  }
}
.large-header {
  font-size: 2rem;

  @include mobile() {
    font-size: 1.5rem;
  }
}
.medium-header {
  font-size: 1.5rem;
}
.normal-header {
  font-size: 1.25rem;
}
.small-header {
  font-size: 1.125rem;
}
.tiny-header {
  font-size: 1.1rem;
}
.lead {
  font-size: 1.125rem;
}
header {
  max-width: 1280px;
  width: 90%;
  padding: 2rem 0;
  margin: auto;
}
footer {
  padding: 4rem 0;
  margin-top: 3rem;
  background-color: $blue-unique;
  color: #fff;
}
.contact {
  @extend .form-container;
  text-align: center;
  p {
    // text-align: justify;
    // text-align-last: center;
    margin: auto;
    max-width: 500px;
  }
  a {
    color: lighten($blue, 37%);
  }
}

.form-container {
  width: 90%;
  max-width: 767px;
  margin: auto;
}

.main-navigation {
  background-color: lighten(#cfd7e4, 10%);
  padding: 2.25rem 0;
  margin-bottom: 3rem;

  ul {
    @extend .form-container;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    justify-self: center;
    list-style: none;
    counter-reset: item;
    flex-wrap: wrap;

    li {
      text-transform: capitalize;
      position: relative;
      font-family: "Lato", sans-serif;
      @include mobile() {
        flex-basis: 100%;
        margin: 0.25rem auto;
      }

      a {
        color: #233464;
        display: block;
        font-size: 1.1rem;
        text-align: center;
        margin: 0.75rem auto;

        &::before {
          counter-increment: item;
          content: counter(item);
          display: flex;
          margin: auto;
          margin-bottom: 0.5rem;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          line-height: 2rem;
          width: 2rem;
          height: 2rem;
          border: 2px solid #233464;
          color: #233464;
          text-decoration: none;
        }
        &.ready {
          &::before {
            border-style: dotted;
          }
        }
        &.active {
          cursor: pointer;
          &::before {
            background-color: $dark-blue-color;
            color: #fff;
          }
        }
        &.current-active {
          cursor: context-menu;
          &::before {
            font-weight: bold;
            color: lighten($dark-orange, 5%);
          }
        }
      }
    }
  }
}

//Form

.radio-label {
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  margin-bottom: 0.3rem;
  // cursor: pointer;
  font-size: 1.05rem;
  user-select: none;
  border: 1px solid transparent;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:focus {
      + .radio-symbol {
        background-color: lighten(#cfd7e4, 7%);
        // border-color: #233464;
      }
    }
  }
  &:hover {
    background-color: lighten(#cfd7e4, 7%);
    // border-color: #233464;
  }

  .radio-symbol {
    position: relative;
    display: flex;
    padding: 0.5rem;
    padding-left: 2.5rem;
    align-items: flex-start;

    &:before {
      position: absolute;
      content: "";
      height: 1rem;
      width: 1rem;
      background-color: lighten(#cfd7e4, 12%);
      border: 1px solid darken(#233464, 15%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      top: 10px;
      justify-content: center;
      left: 0.5rem;
    }
  }

  input:checked {
    ~ .radio-symbol {
      &:before {
        background-color: lighten(#233464, 10%);
        border-color: #fff;
        box-shadow: 0 0 1px 1px #233464;
        border-width: 2px;
      }
    }
  }
}

.checkbox-label {
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  margin: 0.75rem 0;
  // cursor: pointer;
  font-size: 1.05rem;
  user-select: none;
  border: 1px solid transparent;

  input {
    position: absolute;
    opacity: 0;
    // cursor: pointer;

    &:focus {
      + .checkbox-symbol {
        background-color: lighten(#cfd7e4, 7%);
        // border-color: #233464;
      }
    }
  }
  &:hover {
    background-color: lighten(#cfd7e4, 7%);
    // border-color: #233464;
  }

  .checkbox-symbol {
    position: relative;
    display: flex;
    padding: 0.5rem;
    padding-left: 2.75rem;
    align-items: flex-start;

    &:before {
      position: absolute;
      content: "";
      height: 1.1rem;
      width: 1.1rem;
      background-color: lighten(#cfd7e4, 12%);
      border: 2px solid lighten(#233464, 8%);
      border-radius: 2px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      left: 0.5rem;
    }
  }

  input:checked {
    ~ .checkbox-symbol {
      &:before {
        background-color: lighten(#233464, 10%);
      }
      &:after {
        content: "";
        position: absolute;
        display: flex;
        left: 15px;
        top: 9px;
        width: 5px;
        border-radius: 1px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}

input[type="date"] {
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0;
  }

  appearance: none;
  background-image: url("../src/assets/images/calendar.png");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  min-height: 51.75px;
}

.select-block {
  margin: 1.5rem auto;
  label {
    font-size: 1.1rem;
    display: block;
    margin-bottom: 0.8rem;
  }
  select {
    padding: 1rem;
    padding-left: 0.8rem;
    width: 100%;
    font-size: 1.05rem;
    border-radius: 5px;
    border-color: #233464;
    background: url(../src//assets//images/arrow_down.webp) no-repeat
      lighten(#cfd7e4, 10%);
    appearance: none;
    background-position: calc(100% - 1rem) 50%;

    option {
      background-color: lighten(#cfd7e4, 14%);
      font-family: "Lato-regular", sans-serif;
      font-size: 1rem;
    }
  }
}

.form-navigation {
  @extend .form-container;
  margin: 3rem 0;
  display: flex;
  flex-flow: row wrap;

  a {
    text-decoration: none;
  }
}
.navigation-button {
  border: 2px solid $dark-blue-color;
  background: none;
  border-radius: 3px;
  padding: 0.75rem 1.5rem;
  background-color: $dark-blue-color;
  color: #fff;
  font-size: 1.05rem;
  margin: 0 1rem 1rem 0;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background-color: darken($dark-blue-color, 3%);
    color: lighten($dark-orange, 3%);
  }
}

.input-label {
  margin: 2.1rem 0;
  display: block;
  span {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.05rem;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  textarea {
    width: 100%;
    box-sizing: border-box;
    font-size: 1.05rem;
    background-color: lighten(#cfd7e4, 14%);
    padding: 0.8rem;
    border: 1px solid darken(#cfd7e4, 25%);
    border-radius: 3px;
    min-height: 51.75px;
    &:focus,
    &:hover {
      background-color: lighten(#cfd7e4, 12%);
    }
  }
  textarea {
    min-height: 5rem;
  }
}

//File
.file-label {
  // display: flex;

  &__symbol {
    min-width: 170px;
    min-height: 60px;
    font-size: 1.15rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #233464;
    padding: 1rem 3.5rem;
    border-radius: 5px;
    background-color: lighten(#cfd7e4, 10%);
    background-image: url(../src/assets/images/upload.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem);
    background-size: 1.25rem;
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 0;
    &:focus,
    &:active,
    &:hover {
      + span {
        background-color: #cfd7e4;
      }
    }
  }
}
.file-list {
  list-style: none;
  padding: 0;
  margin: 1.5rem auto;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0.75rem;
    background-color: lighten(#cfd7e4, 5%);
    margin-bottom: 3px;
    border-radius: 5px;
    font-size: 1.11rem;
    color: darken(#233464, 10%);
    width: 100%;
  }
  button {
    cursor: pointer;
    border: none;
    background: none;
    color: #8b0000;

    span {
      display: block;
      font-size: 2rem;
    }
  }
}

.error-message {
  width: 100%;
  color: lighten(#8b0000, 3%);
  padding: 0.8rem;
  margin-top: 1rem;
  font-size: 1.125rem;
  background-color: lighten(#8b0000, 69%);
}
.no-display {
  display: none;
}
.no-pointer-events {
  cursor: not-allowed;
}
.receiptInfo {
  margin-bottom: 3rem;
}
.buttonDisabled {
  background-color: #cfd7e4;
  color: darken(#353535, 10%);

  &:hover {
    background-color: #cfd7e4;
    color: darken(#353535, 10%);
    cursor: context-menu;
  }
}
.form-error {
  width: 100%;
  padding: 1.5rem 1rem;
  background-color: #f0f092;
  color: #000000;
  margin-top: 2rem;
}

// .ChatFooter {
//   display: none;
// }

@media (max-width: 700px) {
  .kontaktinfo {
    display: none;
  }

  .ChatFooter {
    display: block;
    padding: 15px;
    border: 1px solid grey;
    width: 30%;
    margin: 0;
    text-align: center;
    position: relative;
    border-bottom: 0;
    margin-top: 2%;
    padding-bottom: 5%;
    border-right: 0;
    right: -70%;
    border-top-left-radius: 25px;
  }

  // #root {
  //   display: none !important;
  // }

  .SuccessDiv {
    position: fixed;
    top: 30%;
    left: 5%;
    right: 5%;
    bottom: auto;
    margin: auto;
    z-index: 9999;
    width: 90%;
    text-align: center;
    background-color: white;
    border: solid black 1px;
    padding: 15px;
  }
}

.semiBold {
  font-weight: 550;
  font-size: 1.05rem;
}

.loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-color: lighten(#cfd7e4, 7%);
    border-right-color: lighten(#233464, 25%);
    animation: spin 1.5s linear infinite;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

.access-denied {
  padding: 1rem;
}

.response-wrapper {
  margin-top: 5rem;
}

.header-logo {
  margin-left: 0;
}
.required {
  &:after {
    content: "*";
    margin-left: 0.75rem;
    color: #e91527f1;
    font-weight: bold;
  }
}

.alert-message {
  @extend .error-message;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 3rem auto;
  max-width: 960px;
}

.question-header {
  display: block;
  margin: 1.25rem 0;
  font-size: 1.075rem;
  font-weight: 550;
  color: #233464;

  &__initial {
    display: initial;
  }
  &__extra-space {
    margin: 1.75rem 0;
  }
  &__black {
    color: #000000;
  }
}

.padding-small {
  padding: 0.75rem;
}
